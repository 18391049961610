import { Link } from "gatsby";
import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Notfound from "../images/404 Illustration.png";

const NotFoundPage = () => (
  <Layout>
    <div className="container mx-auto py-40">
      <Seo title="404: Not found" />
      <div className="flex items-center justify-center">
        <Link to="/">
          <img src={Notfound} alt="" />
        </Link>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
